import React from 'react';
import blackoutBlindsIcon from '@/assets/blindsIcons/blackoutBlindsIcon.png';

import openBlindsIcon from '@/assets/blindsIcons/openBlindsIcon.png';
import stopBlindIcon from '@/assets/blindsIcons/stopBlindIcon.png';
import closeBlindsIcon from '@/assets/blindsIcons/closeBlindsIcon.png';

import ColorButton from '@UI/ColorButton';

const styles = {
  buttonStyle: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 9.5,
    paddingBottom: 9.5,
    background: '#ECF7FB',
    borderRadius: 5,
    flex: 1,
    margin: '0px 3.5px',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 10,
    paddingTop: 10,
  },
  curtainsText: {
    fontFamily: 'NunitoSans',
    fontWeight: '400',
    color: '#404040',
    fontSize: 14,
  },
  leftContainer: {
    display: 'flex',
    flex: 0.4,
    alignItems: 'center',
  },
  rightContainer: {
    display: 'flex',
    flex: 0.6,
    justifyContent: 'space-between',
  },
  imgStyle: {
    height: 18,
    width: 36,
  },
};

function Curtain({ item, func }) {
  return (
    <div style={styles.container}>
      <div style={styles.leftContainer}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <img
            alt="curtainsPlisseIcon"
            src={item.curtain_icon || blackoutBlindsIcon}
            style={{ height: 21, width: 21, paddingRight: 10 }}
          />
          <span style={styles.curtainsText}>
            {item.curtain_name || ''}
          </span>
        </div>
      </div>
      <div style={styles.rightContainer}>
        <ColorButton
          style={styles.buttonStyle}
          onClick={() => { func(item.address, 0); }}
        >
          <img alt="openBlindsIcon" src={openBlindsIcon} style={styles.imgStyle} />
        </ColorButton>
        <ColorButton
          style={styles.buttonStyle}
          onClick={() => { func(item.stop_address, 0); }}
        >
          <img alt="stopBlindIcon" src={stopBlindIcon} style={styles.imgStyle} />
        </ColorButton>
        <ColorButton
          style={styles.buttonStyle}
          onClick={() => { func(item.address, 1); }}
        >
          <img alt="closeBlindsIcon" src={closeBlindsIcon} style={styles.imgStyle} />
        </ColorButton>
      </div>
    </div>
  );
}

export default Curtain;
