import React, { useEffect, useState } from 'react';
import '../styles/HomeStyle.css';
import Paho from 'paho-mqtt';
import { useSnackbar, closeSnackbar } from 'notistack';
import { logEvent } from '@libraries/AmplitudeAnalythics/init';
import AMPLITUDE_EVENT_NAMES from '@libraries/AmplitudeAnalythics/events';

// const client = () => new Paho.Client(
//   '172.16.27.83',
//   Number(18883),
//   `mqtt-async-test-${parseInt(Math.random() * 100, 10)}`,
// );

const useConnectionMQTT = (props) => {
  const {
    data, lights, setLights, conditioner, setСonditioner, sensors,
    setSensors,
  } = props;
  const [isConnected, setIsConnected] = useState(false);
  const [loading, setLoading] = useState(true);

  const { enqueueSnackbar } = useSnackbar();

  const [client] = useState(new Paho.Client(
    String(data?.drivers[0]?.ip),
    Number(data?.drivers[0]?.port),
    `mqtt-async-test-${parseInt(Math.round(Math.random(100000000, 1000000000) * 1000000000), 10)}`,
  ));

  const showNotify = (message, variant, persist) => {
    enqueueSnackbar(message, { persist, variant });
  };

  const onMessage = (message) => {
    // console.log('mesmes', message.destinationName, message.payloadString);
    setLights((prevState) => {
      const newState = prevState.map((item) => {
        const newSubItem = item?.capabilities?.map((subItem) => {
          if (subItem?.feedback_address[0] === message.destinationName) {
            return { ...subItem, fb: parseInt(message.payloadString, 10) };
          }
          return subItem;
        });
        return { ...item, capabilities: newSubItem };
      });

      return newState;
    });
    setСonditioner((prevState) => {
      const newState = prevState.map((item) => {
        const newSubItem = item?.capabilities?.map((subItem) => {
          if (subItem?.feedback_address.length > 1) {
            const isDestination = subItem?.feedback_address?.find?.((val) => val === message.destinationName);
            if (isDestination) {
              const id = isDestination.slice(isDestination.length - 3, isDestination.length);
              return { ...subItem, [`fb-${id}`]: parseInt(message.payloadString, 10) };
            }
            return subItem;
          }
          if (subItem?.feedback_address[0] === message.destinationName) {
            return { ...subItem, fb: parseFloat(message.payloadString, 10) };
          }
          return subItem;
        });
        return { ...item, capabilities: newSubItem };
      });

      return newState;
    });
    setSensors((prevState) => {
      const newState = prevState.map((item) => {
        const newSubItem = item?.capabilities?.map((subItem) => {
          if (subItem?.feedback_address[0] === message.destinationName) {
            return { ...subItem, fb: parseFloat(message.payloadString, 10) };
          }
          return subItem;
        });
        return { ...item, capabilities: newSubItem };
      });

      return newState;
    });
    if (!isConnected) setIsConnected(true);
    setLoading(false);
  };
  const setConnection = () => {
    console.log('try setConnection!');
    // const reconnectTimer = () => {
    //   setTimeout(() => {
    //     console.log('reconnect timer!');
    //     setConnection();
    //   }, 1000);
    // };
    // // reconnectTimer();
    // const reconnect = () => {
    //   console.log('reconnect!');
    //   clearTimeout(reconnectTimer());
    //   reconnectTimer();
    // };

    const onConnectionLost = () => {
      // console.log('onConnectionLost!', responseObject);
      // console.log(`onConnectionLost:${responseObject.errorMessage}`);
      showNotify('Восстанавливаем соединение', 'error', true);
      // client.disconnect();
      // setConnection();
      // reconnect();
    };

    client.connect({
      timeout: 4,
      reconnect: true,
      useSSL: true,
      // keepAliveInterval: 2,
      cleanSession: true,
      onSuccess: () => {
        console.log('Connected!');

        closeSnackbar();
        showNotify('Соедиение установлено!', 'success');

        lights?.forEach((item) => {
          item?.capabilities?.forEach((subItem) => {
            // console.log('fb lights', subItem?.feedback_address);
            subItem?.feedback_address.forEach((fb) => { client.subscribe(fb); });
            console.log('subscribed lights!');
          });
        });
        conditioner?.forEach((item) => {
          item?.capabilities?.forEach((subItem) => {
            // console.log('fb conditioner', subItem?.feedback_address);
            subItem?.feedback_address.forEach((fb) => { client.subscribe(fb); });
            console.log('subscribed conditioner!');
          });
        });
        sensors?.forEach((item) => {
          item?.capabilities?.forEach((subItem) => {
            // console.log('fb conditioner', subItem?.feedback_address);
            subItem?.feedback_address.forEach((fb) => { client.subscribe(fb); });
            console.log('subscribed sensors!');
          });
        });
      },
      onFailure: (err) => {
        // console.log('err', err);
        setIsConnected(false);
        setLoading(false);
        console.log('Failed to connect!');
        setConnection();
      },
    });
    client.onMessageArrived = onMessage;
    client.onConnectionLost = onConnectionLost;
  };

  const changeValue = (addresses, value, qos, isScenario) => {
    // console.log('value', addresses, value, qos);
    if (!isScenario) logEvent(AMPLITUDE_EVENT_NAMES.BLOCK_CONTROL);
    addresses.forEach((address) => {
      const message = new Paho.Message(value.toString());
      // console.log('address', address);
      message.destinationName = address;
      message.qos = qos || 0;
      try {
        // console.log('message', message);
        client.send(message);
        // console.log('message send!');
      } catch (e) {
        console.log('e', e);
      }
    });
  };

  useEffect(() => {
    try {
      setConnection();
    } catch (e) {
      // console.log('e', e);
      setIsConnected(false);
    }
  }, []);

  return { changeValue, loading, isConnected };
};

export default useConnectionMQTT;
