import React, { useState } from 'react';
import '../styles/HomeStyle.css';
import LoadingScreen from '@components/common/LoadingScreen';
import TopMenu from '@components/TopMenu';

import TitleComponent from '@components/TitleComponent';
import ScenarioContainer from '@DeviceContainers/Scenario/ScenarioContainer';
import LightsContainer from '@DeviceContainers/Lights/LightsContainer';
import BlindsContainer from '@DeviceContainers/Blinds/BlindsContainer';
import MultimediaContainer from '@DeviceContainers/MultimediaContainer';
import SensorsContainer from '@DeviceContainers/Sensors/SensorsContainer';
import TemperatureContainer from '@DeviceContainers/Temprature/TemperatureContainer';
import Modal from '@components/ModalComponent/Modal';
import useConnectionMQTT from '@hooks/useConnectionMQTT';
import NoConnectionScreen from '@screens/NoConnectionScreen';

function Home(props) {
  const {
    data,
    lights,
    setLights,
    blinds,
    multimedia,
    conditioner,
    setСonditioner,
    sensors,
    setSensors,
  } = props;
  const {
    logo, type, title, scenarios, realty_object_network_name,
  } = data;
  const { changeValue, loading, isConnected } = useConnectionMQTT({
    data,
    lights,
    setLights,
    conditioner,
    setСonditioner,
    sensors,
    setSensors,
  });
  const [modalObject, setModalObject] = useState({
    isModalOpen: false,
    modalType: '',
  });
  // console.log('blinds', blinds);
  // console.log('lights', lights);
  // console.log('scenarios', data?.scenarios);
  // console.log('multimedia', multimedia);
  // console.log('conditioner', conditioner);
  // console.log('sensors', sensors);
  // console.log('data', data);
  if (loading) return <LoadingScreen />;
  return (
    <div className="container">
      { isConnected
        ? (
          <div>
            <TopMenu logo={logo} />
            <div className="homeContainer">
              <TitleComponent type={type} title={title} />
              <ScenarioContainer
                scenarios={scenarios}
                changeValue={(address, value, qos) => changeValue(address, value, qos, true)}
              />
              <TemperatureContainer
                conditioner={conditioner}
                changeValue={(address, value) => changeValue(address, value)}
                setModalObject={(isModalOpen, modalType) => setModalObject({
                  isModalOpen,
                  modalType,
                })}
              />
              <LightsContainer
                lights={lights}
                changeValue={(address, value) => changeValue(address, value)}
              />
              <BlindsContainer
                blinds={blinds}
                changeValue={(address, value) => changeValue(address, value)}
              />
              <MultimediaContainer
                multimedia={multimedia}
                setModalObject={(isModalOpen, modalType) => setModalObject({
                  isModalOpen,
                  modalType,
                })}
              />
              <Modal
                isModalOpen={modalObject.isModalOpen}
                setIsModalOpen={(val) => setModalObject({ ...modalObject, isModalOpen: val })}
                modalType={modalObject.modalType}
                multimedia={multimedia}
                conditioner={conditioner}
                changeValue={(address, value) => changeValue(address, value)}
              />
            </div>
            <SensorsContainer sensors={sensors} />
          </div>
        ) : <NoConnectionScreen networkName={realty_object_network_name} /> }
    </div>
  );
}

export default Home;
